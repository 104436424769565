import $ from 'jquery';
import prestashop from 'prestashop';

import TableSlider from './table-slider';

export default class BlockFixed {

	suffix_fixed_call = "?fixed_call=1";

	showFixedBlock(size, overlay_active) {

		localStorage.setItem('fixedBlockActive', '1');

		if ($("#block_right_inner").hasClass('search')) {
			$("#block_right_inner").removeClass('search');
			$("#ln_search_fixed").addClass("hidden").detach().appendTo($("#fixed_block"));
		}

		$("#fixed_block").attr('class', '');
		if(overlay_active){
			$('#overlay_back').removeClass('hidden', 1000);
		}else{
			$('#overlay_back').addClass('transparent');
			$('#overlay_back').removeClass('hidden');
		}
		$("#fixed_block").removeClass("hidden");
		$("#block_right").attr("class", "block_right block_right_"+size+"_width unvisible");
		$("#block_right").removeClass("unvisible", 500);

		$('div[id^="trustbadge-container-"]').addClass('hidden');
	  }

	hideFixedBlock() {
		localStorage.setItem('fixedBlockActive', '0');

		$("#block_right").addClass("unvisible", 500);
		$("#fixed_block").addClass("hidden");
		$('#overlay_back').addClass('hidden', 500);

		if ($("#block_right_inner").hasClass('search')) {
			$("#block_right_inner").removeClass('search');
			$("#ln_search_fixed").addClass("hidden").detach().appendTo($("#fixed_block"));
		}

		$('div[id^="trustbadge-container-"]').removeClass('hidden');
	}

	fetchMyAccountData(urlMyAccount){
		$.ajax({
		  url: urlMyAccount+"&caller_page="+prestashop.page.page_name+"&id_object="+$("#fixed_id_object").val()
		})
		.done(function( data ) {
			$("#block_right_inner").attr('class','');
			$("#block_right_inner").html(data);
			$("#block_right_inner").addClass("account");
			$("#login-form").submit(function(){
				localStorage.setItem('loginFromFixedBlock', '1');
				return true;
			});
			localStorage.setItem('loginFromFixedBlock', '0');
		});
	}

	fetchCartData(){
		var tokenFront = $('#data-token').data('token');
		var urlCartFixed = "/panier?fixed_call=1";
		if(tokenFront != null && tokenFront != ""){
			urlCartFixed = urlCartFixed + "&token=" + tokenFront;
		}
		// console.log(tokenFront);
		$.ajax({
		  url: urlCartFixed,
		})
		.done(function( data ) {
			$("#block_right_inner").removeClass();
			$("#block_right_inner").html(data);
			$("#block_right_inner").addClass("cart-preview");
			prestashop.emit(
			  'updateCart', {
				  reason: "maj",
				  resp: "maj"
				}
			);
		});
	}

	fetchProductReviewdata(by,value){
		var urlProductReviewFixed = "/avis-client/produit/"+by+"/"+value+"/"+whetrusted_token;

		$.ajax({
		url: urlProductReviewFixed,
		})
		.done(function( data ) {
			$("#block_right_inner").removeClass();
			$("#block_right_inner").addClass("product-reviews");
			$("#block_right_inner").html(data);
		});

	}

	fetchSiteReviewdata(){
		var urlSiteReviewFixed = "/avis-client/site/"+whetrusted_token;

		$.ajax({
		url: urlSiteReviewFixed,
		})
		.done(function( data ) {

			$("#block_right_inner").removeClass();
			$("#block_right_inner").addClass("site-review");
			$("#block_right_inner").html(data);
		});
	}

	fetchCMSPagedata(id_pages){
		var urlCMSPageFixed = "/guide/"+id_pages+"/"+whcmsfixed_token;

		$.ajax({
			url: urlCMSPageFixed,
		})
		.done(function( data ) {
			let maxw='500px'; // largeur max du slider dans le bloc fixe
			let pr='74px';
			let pl='75px';
			let isMobile = (prestashop.responsive && prestashop.responsive.mobile) ? prestashop.responsive.mobile : ($(window).width() < 567);
			if (isMobile) {
				pr="0";
				pl="0";
			}

			$("#block_right_inner").removeClass();
			$("#block_right_inner").addClass("product-guide");
			$("#block_right_inner").html(data);

			const cmsTableSlider = new TableSlider();
			if (!isMobile) {
				$("#block_right_inner .slider_wrapper").css("max-width", maxw);
				$("#block_right_inner .slider_wrapper").css("margin-left", "auto");
				$("#block_right_inner .slider_wrapper").css("margin-right", "auto");
			}
			$("#block_right_inner .slider_wrapper .labels").addClass("hidden");
			$("#block_right_inner .slider_wrapper .label").css("display", "inline-block");

			if(id_pages==14) {
				$("#slider_products").css("padding-left",pl);
				$("#slider_products").css("padding-right",pr);
				cmsTableSlider.initSlider("#slider_products", true);
			}
			if(id_pages==17) {
				$("#slider_garments_women, #slider_garments_men").css("padding-left",pl);
				$("#slider_garments_women, #slider_garments_men").css("padding-right",pr);
				cmsTableSlider.initSlider("#slider_garments_women", true);
				cmsTableSlider.initSlider("#slider_garments_men", true);
			}
			if(id_pages==18) {
				$("#slider_duvets, #slider_duvets_warmth, #slider_duvets_material").css("padding-left",pl);
				$("#slider_duvets, #slider_duvets_warmth, #slider_duvets_material").css("padding-right",pr);
				cmsTableSlider.initSlider("#slider_duvets", true);
				cmsTableSlider.initSlider("#slider_duvets_warmth", true);
				cmsTableSlider.initSlider("#slider_duvets_material", true);
			}
			if(id_pages==19) {
				$("#slider_blankets, #slider_blankets_warmth, #slider_blankets_washer").css("padding-left",pl);
				$("#slider_blankets, #slider_blankets_warmth, #slider_blankets_washer").css("padding-right",pr);
				cmsTableSlider.initSlider("#slider_blankets", true);
				cmsTableSlider.initSlider("#slider_blankets_warmth", true);
				cmsTableSlider.initSlider("#slider_blankets_washer", true);
			}
			if(id_pages==21) {
				$("#slider_mattress").css("padding-left",pl);
				$("#slider_mattress").css("padding-right",pr);
				cmsTableSlider.initSlider("#slider_mattress", true);
			}
			if(id_pages==43) {
				$("#slider_quilts, #slider_quilts_warmth").css("padding-left",pl);
				$("#slider_quilts, #slider_quilts_warmth").css("padding-right",pr);
				cmsTableSlider.initSlider("#slider_quilts", true);
				cmsTableSlider.initSlider("#slider_quilts_warmth", true);
			}
			if(id_pages==65) {
				$("#slider_pillows_material").css("padding-left",pl);
				$("#slider_pillows_material").css("padding-right",pr);
				cmsTableSlider.initSlider("#slider_pillows_material", true);
			}
		});

	}

	fetchSearchData() {
		if (!$("#block_right_inner").hasClass('search')) {
			$("#block_right_inner").attr('class','search');
			$("#block_right_inner").empty();
			$("#ln_search_fixed").detach().appendTo($("#block_right_inner"));
		}
		$("#ln_search_fixed").show().find('input[name="s"]').val('').focus();
	}

  	init() {
		const self = this;	//Config event listeners block-flixed

		//----- INIT LOCALSTORAGE --------------
		localStorage.setItem('fixedBlockActive', '0');

    //----- Top Right Icon links -----------
    $("#_desktop_search>.user-search>a, #_mobile_search>.user-search>a").first().removeAttr("href");
    $("#_desktop_user_info>.user-info>a, #_mobile_user_info>.user-info>a").first().removeAttr("href");
    $("#fixed_block #block_right>a").first().removeAttr("href");
    $("#_desktop_cart a, #_mobile_cart a").first().removeAttr("href");

		//----- GESTION VOLET MON COMPTE -------
		var url_dest = "";
		if(!prestashop.customer.is_logged){
			url_dest = prestashop.urls.pages.authentication+self.suffix_fixed_call;
		}else{
			url_dest = prestashop.urls.pages.my_account+self.suffix_fixed_call;
		}
		// $("#_desktop_user_info>.user-info>a, #_mobile_user_info>.user-info>a").first().removeAttr("href");

		$('body').on('click', '#_desktop_user_info, #_mobile_user_info', (event)=>{
			event.stopImmediatePropagation();
			self.showFixedBlock(prestashop.isMobile ? "full" : "small", false);
			self.fetchMyAccountData(url_dest);
		});

		$('body').on('click', '#fixed_close_btn, #overlay_back', (event)=> {
      event.preventDefault();
      event.stopImmediatePropagation();
			$("body").css({"overflow":"initial"});
			self.hideFixedBlock();
		})

		//Si on s'est loggué depuis le volet fixed on revient sur le volet
		if(localStorage.getItem('loginFromFixedBlock')=='1'){
			$("#_desktop_user_info").trigger("click");
		}

		//----- GESTION VOLET PANIER -------
		self.initCartResponder();

		//----- GESTION VOLET AVIS CLIENT ---
		self.initProductReviewResponder();
		self.initSiteReviewResponder();

		//----- GESTION VOLET CMS -------
		self.initCMSPageResponder();

		//----- GESTION VOLET SEARCH -------

		self.initSearchResponder();
    }

	initCartResponder(){
		const self = this;
		if (window.prestashop.page.page_name != "cart") {
			// $("#_desktop_cart a, #_mobile_cart a").first().removeAttr("href");

			// $("#_desktop_cart a").click(function(event){
			$('body').on('click', '#_desktop_cart a, #_mobile_cart', (event)=>{
				$("body").css({"overflow":"hidden"});
				event.stopImmediatePropagation();
				self.showFixedBlock((prestashop.isMobile ? "full" : "mid"), false);
				self.fetchCartData();
			});
		}
	}

	initShoppingCartModule() {
		const self = this;
		prestashop.blockcart = prestashop.blockcart || {};

	  	var showModal = prestashop.blockcart.showModal || function (modal) {
			var $body = $('body');
			$body.append(modal);
			$body.one('click', '#blockcart-modal', function (event) {
		  	if (event.target.id === 'blockcart-modal') {
				$(event.target).remove();
		  	}
			});
	  	};

	  	prestashop.on(
			'updateCart',
			function (event) {
				var refreshURL = $('.blockcart').data('refresh-url');
				var requestData = {};
				if (event && event.reason && typeof event.resp !== 'undefined' && !event.resp.hasError) {
					requestData = {
					id_customization: event.reason.idCustomization,
					id_product_attribute: event.reason.idProductAttribute,
					id_product: event.reason.idProduct,
					action: event.reason.linkAction
					};
				}
				if (event && event.resp && event.resp.hasError) {
					prestashop.emit('showErrorNextToAddtoCartButton', { errorMessage: event.resp.errors.join('<br/>')});
				}
				$.post(refreshURL, requestData).then(function (resp) {
					var html = $('<div />').append($.parseHTML(resp.preview));
					$('.blockcart').replaceWith($(resp.preview).find('.blockcart'));
					self.initCartResponder();
					if(!prestashop.isMobile && !prestashop.isTablet){
						if($("#fixed_block").hasClass("hidden") || $('#block_right').hasClass('unvisible') || !$('#block_right').hasClass('block_right_mid_width')){
							$('#_desktop_cart a').trigger('click');
						}
					}else{
						if($("#fixed_block").hasClass("hidden") || $('#block_right').hasClass('unvisible') || !$('#block_right').hasClass('block_right_full_width')){
							$('#_mobile_cart').trigger('click');
						}
					}
				}).fail(function (resp) {
					prestashop.emit('handleError', { eventType: 'updateShoppingCart', resp: resp });
				});
			}
	  	);
	}

	initProductReviewResponder(){
		const self = this;

		if (window.prestashop.page.page_name == "product") {
			$('body').on('click', '.product-prices-left .rating, .review-summary', (event)=>{
				event.preventDefault();
				event.stopImmediatePropagation();
				var type='id';
				var value=whetrusted_id_product;
				var reference = $(event.currentTarget).closest('.rating').data('reference');
				if (reference.length) {
					type='ref';
					value=reference;
				}
				$("body").css({"overflow":"hidden"});
				self.showFixedBlock("mid", false);
				self.fetchProductReviewdata(type,value);
			});

			$('body').on('click', '#product_reviews .slider_page_link a', (event)=>{
				event.preventDefault();
				event.stopImmediatePropagation();
				var type='id';
				var value=whetrusted_id_product;
				var reference = $('#product_reviews').find('.rating').data('reference');
				if (reference.length) {
					type='ref';
					value=reference;
				}
				$("body").css({"overflow":"hidden"});
				self.showFixedBlock("mid", false);
				self.fetchProductReviewdata(type,value);
			});

		}
	}

	initSiteReviewResponder(){
		const self = this;

		if (window.prestashop.page.page_name == "index") {
			$('body').on('click', '.slider_avis .slider_page_link a', (event)=>{
				event.preventDefault();
				event.stopImmediatePropagation();
				$("body").css({"overflow":"hidden"});
				self.showFixedBlock((prestashop.isMobile ? "full" : "mid"), false);
				self.fetchSiteReviewdata();
			});
		}
	}

	initCMSPageResponder(){
		const self = this;

		$('body').on('click', '[data-id-cms]', (event)=>{
			event.preventDefault();
			event.stopImmediatePropagation();
			var id_pages = $(event.currentTarget).data('id-cms');
			$("body").css({"overflow":"hidden"});
			self.showFixedBlock((prestashop.isMobile ? "full" : "mid"), false);
			self.fetchCMSPagedata(id_pages);
		});
	}

	initSearchResponder(){
		const self = this;
		$('body').on('click', '#_desktop_search, #_mobile_search', (event)=>{
			event.preventDefault();
			event.stopImmediatePropagation();
			if (!$("#block_right_inner").hasClass('search')) {
				$("body").css({"overflow":"hidden"});
				$("#block_right_inner").attr('class','search');
				$("#block_right_inner").empty();
				$("#ln_search_fixed").detach().appendTo($("#block_right_inner"));
				self.showFixedBlock((prestashop.isMobile ? "full" : "small"), false);
        self.fetchSearchData();
				$("#ln_search_fixed").show().find('input[name="s"]').val('').focus();
			}
		});
	}

}
