import $ from 'jquery';
import 'owl.carousel';

export default class ContactSlider {
  initSliderContact() {
	const self = this;
	$('#slider_contact_content').owlCarousel({
		items: 1,
		loop: true,
		nav: false,
		autoplay: true,
		responsiveClass:true,
		responsive:{
			0:{
				dots:true,
				dotsContainer: "#slider_contact_pagination",
			},
			600:{
				dots:false,
			},
		}
	});
  }
}