import $ from 'jquery';
import prestashop from 'prestashop';
import 'owl.carousel';

const isMobile = (prestashop.responsive && prestashop.responsive.mobile) ? prestashop.responsive.mobile : ($(window).width() < 1200)

export default class ProductSlider {

	constructor(){
		this.done=false;
		this.indexNuancier=0;
		this.categoryNuancier=[
			{
				'id':226,
				'entity':'category',
			},
		];

		this.productNuancier=[
			{
				'id':2615,
				'entity':'product',
				'cover':'nuancier-nid-abeille.jpg',
			},
			{
				'id':2616,
				'entity':'product',
				'cover':'nuancier-nid-abeille.jpg',
			},
			{
				'id':2625,
				'entity':'product',
				'cover':'nuancier-nid-abeille-2625.png',
			},
			{
				'id':2630,
				'entity':'product',
				'cover':'nuancier-coton-chambray.jpg',
			},
			{
				'id':2631,
				'entity':'product',
				'cover':'nuancier-coton-chambray.jpg',
			},
			{
				'id':2632,
				'entity':'product',
				'cover':'nuancier-coton-chambray.jpg',
			},
			{
				'id':2633,
				'entity':'product',
				'cover':'nuancier-coton-chambray.jpg',
			},
			{
				'id':2635,
				'entity':'product',
				'cover':'nuancier-lambswool.jpg',
			},
			{
				'id':2636,
				'entity':'product',
				'cover':'nuancier-lambswool.jpg',
			},
		];

		this.coverDir='themes/labelnaturel/assets/img/product/slider/';
	}

	/**
	 * extrait l'ID de la propriété de l'objet correspondant à substring
	 *
	 * @param {*} object
	 * @param {*} subString
	 * @returns
	 */
	getIdFromProperty(object, subString) {
		// get all the keys from the object
		let id=false;
		const regexp=new RegExp('^'+subString+'([0-9]+)');
		let done=false;
		Object.keys(object).forEach((item)=>{
			if(!done) {
				// does key match ?
				let ids=item.match(regexp);
				if (Array.isArray(ids) && ids.length) {
					id=ids[1];
					done=true;
				}
			}
		});
		return id;
	}

	modulo(num,n) {
		var m = (( num % n) + n) % n;
		return m < 0 ? m + Math.abs(n) : m;
	};

	addNuancier() {
		const self = this;
		let bodyClasses = prestashop.page.body_classes;

		// let entityId = self.getIdFromProperty(bodyClasses,'product-id-category-');
		let productId=self.getIdFromProperty(bodyClasses,'product-id-');
		let categoryId=self.getIdFromProperty(bodyClasses,'product-id-category-');

		let slides = [];

		// do this one time only
		if ( !self.done ) {
			// get array of slide cover urls filtered from arrays on id

			// products
			slides = self.productNuancier.filter(x => x.id==productId);
			if (slides.length) {
				slides.forEach(function(slide) {
					// add slide
					let nuancier = prestashop.urls['base_url']+self.coverDir+slide.cover;
					let productCover = $('#slider_product_cover .product-cover').eq(0).clone();
					$('img.js-qv-product-cover', productCover).attr('src', nuancier);
					productCover.appendTo('#slider_product_cover');
					// add link
					let productVariantsItems = ($('.product-information .product-actions .product-variants-item'));
					productVariantsItems.each(function(index) {
						if ($(this).children('.couleurs').length) {
							let linkText = 'Voir le nuancier';
							let nuancierLink = '<li class="float-xs-right input-container mr-0"><a class="jump-to-nuancier" href="#" data-id="'+($('#slider_product_nav button').length-1)+'">'+linkText+'</a></li>';
							$('.couleurs+ul', productVariantsItems[index]).append(nuancierLink);

							$(document.body).on('click', '.jump-to-nuancier', function(e) {
								if (!isMobile) {
									$('#slider_product_nav button').eq($(this).data('id')).trigger('click');
								} else {
									$('#slider_product_cover button').eq($(this).data('id')).trigger('click');
								}
							});
						}
					});

				});

				this.done=true; // do this one time only
			}

			slides = self.categoryNuancier.filter(x => x.id==categoryId);
			if (slides.length) {
				slides.forEach(function(slide) {
					// add slide
					let nuancier = prestashop.urls['base_url']+self.coverDir+'nuancier-'+slide.id+'.jpg';
					let productCover = $('#slider_product_cover .product-cover').eq(0).clone();
					$('img.js-qv-product-cover', productCover).attr('src', nuancier);
					productCover.appendTo('#slider_product_cover');
					// add link
					let productVariantsItems = ($('.product-information .product-actions .product-variants-item'));
					productVariantsItems.each(function(index) {
						if ($(this).children('.couleurs').length) {
							let linkText = 'Voir le nuancier';
							let nuancierLink = '<li class="float-xs-right input-container mr-0"><a class="jump-to-nuancier" href="#" data-id="'+($('#slider_product_nav button').length-1)+'">'+linkText+'</a></li>';
							$('.couleurs+ul', productVariantsItems[index]).append(nuancierLink);

							$(document.body).on('click', '.jump-to-nuancier', function(e) {
								$('#slider_product_nav button').eq($(this).data('id')).trigger('click');
							});
						}
					});

				});

				this.done=true; // do this one time only
			}


		}

		return this;
	}

	initSliderProduct() {
		return this.initSliderProduct_V2();
	}

	initSliderProduct_V2() {
		const self = this;

		$("#slider_product_cover").owlCarousel({
			loop:true,
			items:1,
			margin:0,
			stagePadding: 0,
			autoplay:false,
			nav:true,
			navText:[],
			dots:true,
      video:true,
		});

		if (!isMobile) {

			var dotcount = 1;

			$('#slider_product_cover .owl-dot').each(function() {
				$( this ).addClass( 'dotnumber' + dotcount);
				$( this ).attr('data-info', dotcount);
				dotcount=dotcount+1;
			});

			var slidecount = 1;

			$('#slider_product_cover .owl-item').not('.cloned').each(function() {
				$( this ).addClass( 'slidenumber' + slidecount);
				slidecount=slidecount+1;
			});


			$('#slider_product_cover .owl-dots').detach().appendTo($('#slider_product_nav'));
			$('#slider_product_nav .owl-dots .owl-dot').each(function() {
        var $this=$(this);
				var grab = $(this).data('info');
				var slidegrab = $('.slidenumber'+ grab +'>.product-cover>img').attr('src');
        var isVideo = $('.slidenumber'+ grab +'>.product-cover.video-cover').length;
        if (isVideo) {
          $this.addClass('video-button');
        }
				$(this).css("background-image", "url("+slidegrab+")");
			});

			$('#slider_product_cover .owl-nav').detach().insertBefore('#slider_product_cover').removeClass("disabled");
			$('.owl-nav .owl-prev').addClass(['triangle','triangle-top']);
			$('.owl-nav .owl-next').addClass(['triangle','triangle-bottom']);

			var perPage=6;
			var currentDot=0;
			var currentPage=0;
			var currentOffset=0;
			var dots=$('#slider_product_nav .owl-dots .owl-dot');
			var nbDots=dots.length;
			var nbPages=Math.trunc(nbDots/perPage);
			var posInPage=0;
			var posInLastPage=0;
			var paddingTop=30;  // paddingTop #slider_product_nav - margin-top 1er item
			// $(document.body).on('click', '.owl-nav .owl-next', function(e) {
			// 	e.preventDefault();
			// 	currentDot=dots.index($('#slider_product_nav .owl-dots .owl-dot.active')[0]);
			// 	currentPage=Math.trunc(currentDot/perPage);
			// 	posInPage=self.modulo(currentDot,perPage);
			// 	let offset=posInPage==0?currentPage*(perPage*80+paddingTop):currentOffset;
			// 	currentOffset=offset;
			// 	$('#slider_product_nav .owl-dots').css('margin-top',-1*offset+'px');
			// });

			$(document.body).on('click', '.owl-nav .owl-prev', function(e) {
				e.preventDefault();
				currentDot=dots.index($('#slider_product_nav .owl-dots .owl-dot.active')[0]);
				currentPage=Math.trunc(currentDot/perPage);
				posInPage=self.modulo(currentDot,perPage);
				if (posInPage==Math.trunc(nbDots/perPage) && currentPage==nbPages) {
					// aller à la fin de la liste
					posInPage=perPage-1;
				}
				let offset=posInPage==(perPage-1)?currentPage*(perPage*80+paddingTop):currentOffset;

				currentOffset=offset;
				$('#slider_product_nav .owl-dots').css('margin-top',-1*offset+'px');
			});


		}

		// show dots even if there less than 3+1 items
		$('#slider_product_cover .owl-dots').removeClass("disabled");

		return self;
  	}

  	initSliderPF() {
		const self = this;
		let nbItems = 4;
		let doLoop=false;
		let hasDots=false;

		// if (prestashop.responsive.mobile) {
		if (isMobile) {
			nbItems = 1;
			doLoop=true;
			hasDots=true;
		}

		$('#slider_points_forts').owlCarousel({
			loop:doLoop,
			items:nbItems,
			margin:0,
			stagePadding: 0,
			autoplay:false,
			nav: false,
			dots: hasDots,
		});
  	}

  	initSliderPacks() {
		const self = this;
		let nbItems = 3;
		let doLoop=false;
		let hasDots=false;

		// if (prestashop.responsive.mobile) {
		if (isMobile) {
			nbItems = 1;
		}

		$('#pack_slider_content').owlCarousel({
			items: nbItems,
			loop: doLoop,
			nav: true,
			autoplay: false,
			dots: hasDots,
			navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
			responsiveClass:true,
			responsive:{
				0:{
					dots:true,
				},
				600:{
					dots:false,
				},
			}
		});
  	}

}
