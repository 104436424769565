import $ from 'jquery';
import prestashop from 'prestashop';

export default class Subcategorynav {
	
    isMobile = (prestashop.responsive && prestashop.responsive.mobile) ? prestashop.responsive.mobile : ($(window).width() < 567);
    top_h = $("#header").outerHeight();

    init() {
		const self = this;	
		
        self.initStickySubMenu($(window).width(), $(window).scrollTop());

        $(window).on('resize', (event)=> {
            self.initStickySubMenu($(window).width(), $(window).scrollTop());
        });

        if (!this.isMobile) {
            $(window).on('scroll', (event)=> {
                self.initStickySubMenu($(window).width(), $(window).scrollTop());
            });
        }

        $(".nav-subcategories").on('click', 'a', (event) => {
            event.preventDefault();

            $(".nav-subcategories").find('a').each(function(i) {
                $(this).removeClass('active');
            });
            $(event.currentTarget).addClass('active');
        
            let selector = '#category-'+event.currentTarget.dataset.id;
            var scrollValue = 0;
        
// console.log('--------------------------------');
// console.log('selector: '+$(selector).offset().top);
// console.log('selector h2: '+$(selector+" h2").outerHeight());
// console.log('header: '+$('#header').outerHeight());
// console.log('subcats: '+$('.nav-subcategories').outerHeight());
// console.log('---->');
            if (!this.isMobile) {
                if ($('.nav-subcategories').css('position')=='fixed') {
                    scrollValue = $(selector).offset().top - ($('#header').outerHeight()+$('.nav-subcategories').outerHeight() + $(selector+" h2").outerHeight());
                } else {
                    scrollValue = $(selector).offset().top - 2 * ($('#header').outerHeight()+$('.nav-subcategories').outerHeight());
                }
            } else {
                scrollValue = $(selector).offset().top - ($('#header').outerHeight() + $(selector+" h2").outerHeight());              
            }
            window.scroll({
                top: scrollValue,
                behavior: 'smooth'
            });
// console.log('calcul: '+scrollValue);
// console.log('position: '+$(document).scrollTop());
// console.log('--------------------------------');

        });
    }

    initStickySubMenu(scroll) {
        var zIndex=10;
        // if ($(".nav-subcategories").hasClass('sticky_menu')) {
            if ($(".nav-subcategories").hasClass('sticky-submenu')) {
            if (!this.isMobile) {
                if (scroll >= this.top_h) {
                    jQuery(".nav-subcategories").css({
                        "position": "fixed",
                        "width": "100%",
                        "top": this.top_h+"px",
                        "z-index": zIndex,
                    });
                } else {
                    jQuery(".nav-subcategories").css({
                        "position": "relative",
                        "z-index": zIndex,
                        "top":"initial",
                    });
                }
            }
        }
    }
}
