import $ from 'jquery';
import 'owl.carousel';

export default class TableSlider {

    constructor() {
        this.defaultOptions = {
            responsiveClass: true,
            responsive: {
                0: {
                    items: 1,
                    loop: true,
                    autoplay: true,
                    nav: true,
                    navContainer: ".slider_table_pagination",
                    navText: ['<span class="arrow-left"></span>', '<span class="arrow-right"></span>'],
                    dots: false,
                },
                600: {
                    items: 2,
                    loop: true,
                    autoplay: true,
                    nav: true,
                    navContainer: ".slider_table_pagination",
                    navText: ['<span class="arrow-left"></span>', '<span class="arrow-right"></span>'],
                    dots: false,
                },
                1100: {
                    items: 4,
                    loop: false,
                    autoplay: false,
                    nav: false,
                    dots: false,
                },
            }
        };
    }

    initSlider(selector, isBlockFixed) {
        if (typeof selector !== "undefined") {} else return false;

        let largeSelectors6 = ['#slider_products', '#slider_duvets', '#slider_quilts', '#slider_blankets', '#slider_garments_women'];
        let largeSelectors7 = ['#slider_garments_men'];
        let isMobile = (prestashop.responsive && prestashop.responsive.mobile) ? prestashop.responsive.mobile : ($(window).width() < 567);
        isBlockFixed = (typeof isBlockFixed !== "undefined");

        let options = JSON.parse(JSON.stringify(this.defaultOptions));

        if (typeof options.responsive[0] !== "undefined")
            options.responsive[0].navContainer = selector + "_pagination";
        if (typeof options.responsive[600] !== "undefined")
            options.responsive[600].navContainer = selector + "_pagination";

        options.responsive[600].items = ( isBlockFixed || isMobile ? 1 : (largeSelectors6.includes(selector) ? 6 : (largeSelectors7.includes(selector) ? 7 : 4)) );
        options.responsive[1100].items = ( largeSelectors6.includes(selector) ? 6 : (largeSelectors7.includes(selector) ? 7 : 4));

        if (isBlockFixed) { 
            delete options.responsive[1100];
        }

        $(selector).owlCarousel(options);
    }
}