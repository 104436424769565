import $ from 'jquery';
import 'owl.carousel';

export default class ReviewSlider {
  initSliderAvis() {
	const self = this;
	$('#slider_avis_content').owlCarousel({
		items: 1,
		loop: true,
		nav: true,
		autoplay: true,
		navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
		responsiveClass:true,
		responsive:{
			0:{
				dots:true,
			},
			600:{
				dots:false,
			},
		}
	});
  }

}