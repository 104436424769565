import $ from 'jquery';
import prestashop from 'prestashop';

export default class FeatureFilters {
	
    init() {
		const self = this;	
		
        $('.category-filter').on('click', 'li[class^="feature-"]', (event)=> {
            event.preventDefault();
            let $this = $(event.currentTarget);
            // bouton
            if ($this.is('.feature-0')) {
                $('.category-filter li.feature-0 span.filter').show();
                $('.category-filter li.feature-0 span.cancel').hide();

                $('#js-product-list .product-miniature').each((index, selector) => {
                    $(selector).parent().show();
                });
            } else {
                $('.category-filter li.feature-0 span.filter').hide();
                $('.category-filter li.feature-0 span.cancel').show();
            }
             
            // filtre
            $('.category-filter li[class^="feature-"]').each((index, selector) => {
                $(selector).removeClass('active');
            });
            let selectedFilter=$this.data('feature').trim();
            $this.addClass('active');

            // produits
            $('#js-product-list .product-miniature').each((index, selector) => {
                let features=$(selector).data('features').trim().split(/\s+/);
                let hasFeature=false;
                $.each(features, function(index, item) {
                    if (item === selectedFilter) {
                        hasFeature=true;
                    }
                });

                if (!hasFeature) {
                    $(selector).parent().hide();
                } else {
                    $(selector).parent().show();
                }
            });
        });        
    }
}

