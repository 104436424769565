import $ from 'jquery';
import prestashop from 'prestashop';
import 'owl.carousel';

const isMobile = (prestashop.responsive && prestashop.responsive.mobile) ? prestashop.responsive.mobile : ($(window).width() < 567)

export default class AdvantageSlider {
  initSliderAdvantages() {
	const self = this;
	if (isMobile) {
		let nbItems = 1;
		let doLoop=false;
		let hasDots=false;
		
		$('#slider_advantages').owlCarousel({
			items: nbItems,
			loop: doLoop,
			nav: true,
			autoplay: false,
			dots: hasDots,
			responsiveClass:true,
			responsive:{
				0:{
					dots:true,
				},
				600:{
					dots:false,
				},
			}
		});
	}
  }

}