import $ from 'jquery';
import 'owl.carousel';

export default class HomeSlider {
	initSliderHome() {
		const self = this;
		$('#slider_home_content').owlCarousel({
			items: 1,
			loop: true,
			nav: false,
			autoplay: true,
			responsiveClass:true,
			lazyLoad: true,
			autoplayTimeout: 5000,  
			responsive:{
				0:{
					dots:true,
					dotsContainer: "#slider_home_pagination",
				},
				600:{
					dots:false,
				},
			}
		});
	}
	
	initSliderCollections(){
		const self = this;
		$('#slider_collections_content').owlCarousel({
			items: 3,
			loop: true,
			nav: false,
			autoplay: true,
			margin: 30,
			responsiveClass:true,
			slideBy: 3,
			responsive:{
				0:{
					items:1,
					slideBy: 3,
				},
				600:{
					items:3,
				},
			}
		});
		
		$('#slider_collections_content .slide').on('mouseout', function(){
			$(this).css("background-image", "url("+$(this).data('path-root')+"index/bloc_collections/"+$(this).data('name')+".jpg)");
		});
		
		$('#slider_collections_content').on('changed.owl.carousel', function(event) {
			var itemsActive = $('#slider_collections_content .owl-item');
			var itemActive = itemsActive[event.item.index+1];
			$("#titre_collection").html($(itemActive).children('.slide').first().attr('data-titre'));
		});
		
		$('.collectionsBtnNext').on('click', function() {
			$('#slider_collections_content').trigger('next.owl.carousel');
		});
		$('.collectionsBtnPrev').on('click', function() {
			$('#slider_collections_content').trigger('prev.owl.carousel');
		});
		
	}
}
