import $ from 'jquery';
import prestashop from 'prestashop';

// export default class WHCustom {
export class WHCustom {
	
    init() {
		const self = this;	//Config event listeners block-flixed
		
        $('.contact-submit').on('click', (event)=> {
            const form = $(event.currentTarget).closest('form');
            form.trigger("submit");
        });
    }
}

export function waitForElementToExist(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }
  
        const observer = new MutationObserver(() => {
            if (document.querySelector(selector)) {
            resolve(document.querySelector(selector));
            observer.disconnect();
            }
        });
  
        observer.observe(document.body, {
            subtree: true,
            childList: true,
        });
    });
  }
  
export function waitForElement(querySelector, timeout) {
    return new Promise(function (resolve, reject) {
        var timer = false;
        if (document.querySelectorAll(querySelector).length) return resolve();
        var observer = new MutationObserver(function () {
            if (document.querySelectorAll(querySelector).length) {
            observer.disconnect();
            if (timer !== false) clearTimeout(timer);
            return resolve();
            }
        });
        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
        if (timeout) timer = setTimeout(function () {
            observer.disconnect();
            reject();
        }, timeout);
    });
}
  